<template>
  <div class="chat-main">
    <div class="wrapper">
      <div class="container">
        <div class="left">
          <div class="top">
            <!-- <input type="text" placeholder="Search" />
            <a href="javascript:;" class="search"></a> -->
            logo
          </div>
          <ul class="people">
            <li
              class="person"
              :class="{ active: item.id === selectTheme.id }"
              data-chat="person1"
              v-for="(item, i) in theme"
              :key="i"
              @click="getThemeMessage(item)"
            >
              <img :src="headImage" alt="" class="headImage" />
              <span class="name">{{ item.name }}</span>
              <span class="time">{{ item.showChatTime }}</span>
              <span class="preview">{{ item.describe || "暂无新消息" }}</span>
              <span
                class="count"
                v-if="item.count && item.id !== selectTheme.id"
                >{{ item.count }}</span
              >
              <!-- <img :src="more" alt="" class="more" /> -->
            </li>
            <!-- <li class="person active" data-chat="person2">
              <img :src="headImage" alt="" class="headImage" />
              <span class="name">Dog Woofson</span>
              <span class="time">1:44 PM</span>
              <span class="preview">I've forgotten how it felt before</span>
              <img :src="more" alt="" class="more" />

            </li> -->
          </ul>
        </div>
        <div class="right" @click="readMessage(selectTheme)">
          <div class="top">
            <span
              >To: <span class="name">{{ selectTheme.name }}</span></span
            >
          </div>
          <div class="chat active-chat" data-chat="person2" id="person2">
            <!--  <div class="conversation-start">
              <span>Today, 5:38 PM</span>
            </div> -->
            <div
              class="bubble"
              v-for="(item, index) in chat[selectTheme.id]"
              :key="index"
              :class="{ you: item.type == 0, me: item.type == 1 }"
            >
              <template v-if="item.msg_type === 'text'">
                {{ item.content }}
              </template>
              <template v-if="item.msg_type === 'image'">
                <img
                  :src="item.content"
                  alt=""
                  style="width: 100px; cursor: pointer; border-radius: 5px"
                  @click="handleRenderImage(item)"
                />
              </template>
            </div>
          </div>
          <div class="write">
            <!-- <a href="javascript:;" class="write-link attach"></a> -->
            <input
              type="text"
              v-model="content"
              @keyup.enter="handleSendText"
            />
            <a
              href="javascript:;"
              class="write-link attach"
              @click="
                () => {
                  this.$refs.inputFile.click();
                }
              "
            ></a>
            <a
              href="javascript:;"
              class="write-link send"
              @click="handleSendText"
            ></a>
          </div>
          <input
            type="file"
            style="display: none"
            ref="inputFile"
            @change="inputFileChange"
            accept="image/png,image/jpg,image/jpeg"
          />
        </div>
      </div>
    </div>

    <Modal v-model="preview.status" title="查看">
      <div style="text-align: center">
        <img :src="preview.src" alt="" srcset="" style="max-width: 100%" />
      </div>
      <div slot="footer"></div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const headImage = require("../../assets/bo-jackson.jpg"),
  more = require("../../assets/more.jpg");
export default {
  data() {
    return {
      headImage,
      more,
      preview: {
        status: false,
        src: "",
      },
      content: "",
    };
  },
  mounted() {
    this.UserInfo();
    // setInterval(()=>{
    //   this.setScrollHeight();
    // },500)
  },
  methods: {
    ...mapActions([
      "UserInfo",
      "GetChat",
      "SendImagesMessage",
      "SendTextMessage",
      "ReadMessage",
    ]),
    getThemeMessage(row) {
      this.GetChat(row);
      this.ReadMessage(row.id);
      setTimeout(() => {
        this.setScrollHeight();
      }, 100);
    },
    handleRenderImage(row) {
      console.log("row", row);
      this.preview = {
        src: row.content,
        status: true,
      };
    },
    inputFileChange(e) {
      const file = e.target.files[0];
      let data = new FormData();
      data.append("file", file);
      data.append("businessid", this.selectTheme.business_id);
      data.append("touser", this.selectTheme.openid);
      data.append("theme_id", this.selectTheme.id);
      this.SendImagesMessage(data).then((res) => {
        setTimeout(() => {
          this.setScrollHeight();
        }, 100);
        if (!res.status) {
          this.$Message.error(res.msg);
        }
      });
    },
    handleSendText() {
      const data = {
        touser: this.selectTheme.openid,
        theme_id: this.selectTheme.id,
        businessid: this.selectTheme.business_id,
        content: this.content,
      };
      if (!data.content) return false;
      this.content = "";
      this.SendTextMessage(data).then((res) => {
        this.setScrollHeight();
        if (!res.status) {
          this.$Message.error(res.msg);
        }
      });
    },
    setScrollHeight() {
      console.log("滚动2");
      const showContent = document.getElementById("person2");
      showContent.scrollTop = showContent.scrollHeight;
    },
    readMessage(row){
      console.log('rrr',row)
      this.ReadMessage(row.id);
    }
  },
  computed: mapGetters({
    theme: "theme",
    chat: "chat",
    selectTheme: "selectTheme",
  }),
  watch: {
    chat: function (a, b) {
      console.log("滚动");
      let t = 10;
      if (
        b[this.selectTheme.id][b[this.selectTheme.id].length - 1].msg_type ===
        "image"
      )
        t = 300;
      setTimeout(() => {
        const showContent = document.getElementById("person2");
        showContent.scrollTop = showContent.scrollHeight;
      }, t);
    },
    theme: (a, b) => {
      console.log("滚动1");
      setTimeout(() => {
        const showContent = document.getElementById("person2");
        showContent.scrollTop = showContent.scrollHeight;
      }, 10);
    },
  },
};
</script>
<style lang='less'  >
@import "./index.less";
</style>